<script lang="ts" setup>
import {
  CheckIcon,
  CopyIcon,
} from 'lucide-vue-next';

const TIMEOUT_MILLISECONDS = 3000;

withDefaults(defineProps<{
  isDarkBackground?: boolean;
  promoCode: string;
}>(), {  })

const copied = ref(false);

const { copy } = useClipboard();

const copyPromoCode = async () => {
  await copy(__props.promoCode);

  copied.value = true;

  setTimeout(() => {
    copied.value = false;
  }, TIMEOUT_MILLISECONDS);
};
</script>

<template>
  <button
    :class="cn(
      'flex items-center justify-center justify-self-end rounded-md border border-dashed px-3 py-1 text-sm font-semibold lg:py-2',
      isDarkBackground ? 'text-white border-white/50  hover:border-white' : 'text-dark border-dark/50 hover:border-dark',
      copied ? 'text-green bg-white !border-transparent' : 'bg-transparent',
    )"
    @click="copyPromoCode()"
  >
    <template v-if="copied">
      <CheckIcon class="mr-2 size-4" />
      {{ $t('common.copied') }}
    </template>

    <template v-else>
      {{ promoCode }}
      <CopyIcon class="ml-2 size-4" />
    </template>
  </button>
</template>
