<script setup lang="ts">
import MainNavigationTabs from './MainNavigationTabs.vue';
import type { DefaultLayoutQuery } from '#gql';

withDefaults(defineProps<{
  mainNavigationItems: DefaultLayoutQuery['mainNavigation']['items'];
}>(), {  })

const {
  firstLevelActiveIndex,
  updateFirstLevelActiveIndex,
} = useNavigationActiveIndex();

const flyoutItemId = computed(() => (firstLevelActiveIndex.value > -1
  ? __props.mainNavigationItems?.[firstLevelActiveIndex.value]._uid || null
  : null));
</script>

<template>
  <div
    class="main-navigation relative flex w-full min-w-0 flex-col whitespace-nowrap border-b border-grey-mid"
    @mouseleave="updateFirstLevelActiveIndex(-1)"
  >
    <span>
      <UiScroller
        has-full-height-buttons
        class="justify-center"
      >
        <template #scrollerContent>
          <span class="flex flex-nowrap justify-start">
            <MainNavigationTabs :tabs="mainNavigationItems" />
          </span>
        </template>
      </UiScroller>

      <FlyoutNavigation
        v-if="flyoutItemId"
        :key="flyoutItemId"
        class="hidden md:block"
        :flyout-item-id="flyoutItemId"
      />
    </span>
    <LoadingOverlay
      class="!top-[44px] hidden md:flex"
      :is-loading="!!flyoutItemId"
    />
  </div>
</template>
