<script setup lang="ts">
import { CACHE_KEY_TAGS } from 'configs';
import { getDurationInSeconds } from 'utils';

const { locale } = useI18n();
const { fetchCustomer } = useCustomer();
const GqlInstance = useGql();

const { isModalVisible } = useModalVisible();

const { data } = await useCachedAsyncData(
  `default-layout-${locale.value}`,
  () => GqlInstance('DefaultLayout', { localeCode: locale.value }),
  {
    clientMaxAge: getDurationInSeconds({ minutes: 15 }),
    serverCacheTags: [
      CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DEFAULT,
      CACHE_KEY_TAGS.NUXT_MULTI_CACHE_GLOBAL_CONTENT,
    ],
    serverMaxAge: getDurationInSeconds({ hours: 1 }),
  },
);

const relevantPromobar = data.value?.promobars.filter((promobar) => {
  const saleEnd = new Date(promobar.end).getTime();
  const now = new Date().getTime();
  return saleEnd > now;
}).sort((a, b) => new Date(a.end).getTime() - new Date(b.end).getTime())
  .shift();

onMounted(async () => {
  try {
    await fetchCustomer();
  } catch {}
});
</script>

<template>
  <ConfigProvider>
    <div
      class="app"
      :class="{ 'modal-open': isModalVisible }"
    >
      <HeaderComponent
        v-if="data?.header"
        :header-data="data.header"
        :promobar="relevantPromobar"
      />
      <MainNavigation
        v-if="data?.mainNavigation.items"
        :main-navigation-items="data.mainNavigation.items"
      />
      <BurgerMenu
        v-if="data?.mainNavigation && data?.header"
        :header-data="data.header"
        :main-navigation-items="data.mainNavigation.items"
      />

      <slot />

      <FooterComponent
        v-if="data?.footer"
        :footer="data.footer"
      />
      <AuthDialog />
    </div>
  </ConfigProvider>
</template>
