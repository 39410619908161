<script setup lang="ts">
import type { NavigationItemQuery } from '#gql';

withDefaults(defineProps<{ navigationItemGroup: NonNullable<NavigationItemQuery['navigationItem']['groups']>[number] }>(), {  })

const { updateFirstLevelActiveIndex } = useNavigationActiveIndex();

const { getStoryblokUrl } = useStoryblokData();
</script>

<template>
  <div
    v-if="navigationItemGroup.columns"
    class="flyout-columns-desktop hidden h-auto w-full grid-cols-5 gap-x-3.5 overflow-y-auto overflow-x-hidden bg-white px-5 py-10 pb-3 lg:grid"
  >
    <span
      v-for="column in navigationItemGroup.columns"
      :key="column.id"
    >
      <span
        v-for="block in column.blocks"
        :key="block.id"
        class="mb-5 block"
      >
        <NuxtLink
          class="block w-full cursor-default"
          :to="getStoryblokUrl(block.link)"
        >
          <span
            class="block text-sm font-semibold text-dark"
            :class="{ 'cursor-pointer hover:text-semidark': !!block.link }"
            v-text="block.title"
          />
        </NuxtLink>
        <span
          v-for="flyoutLink in block.links"
          :key="flyoutLink.id"
          @click="updateFirstLevelActiveIndex(-1)"
        >
          <NuxtLink
            class="mt-2 block w-full cursor-pointer text-xs leading-4"
            :to="getStoryblokUrl(flyoutLink.link)"
          >
            <span
              class="cursor-pointer text-grey-semidark hover:text-grey-dark"
              v-text="flyoutLink.title"
            />
          </NuxtLink>
        </span>
      </span>
    </span>
  </div>
</template>
