<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import {
  SearchIcon,
  XIcon,
} from 'lucide-vue-next';

const translationKeys = { searchPlaceholder: 'common.searchPlaceholder' };

const localePath = useLocalePath();

const searchQuery = ref('');

const inputElement = ref<HTMLInputElement | null>(null);

const navigateToResultPage = async () => {
  await navigateTo({
    path: localePath('/search'),
    query: { q: searchQuery.value },
  });
};
</script>

<template>
  <OnClickOutside @trigger="searchQuery = ''">
    <input
      ref="inputElement"
      v-model="searchQuery"
      class="search-box h-8 w-full text-ellipsis rounded-3xl border-[1.5px] border-dark px-7 pl-10 outline-none transition-all duration-150 placeholder:text-sm placeholder:text-dark focus-visible:border-dark focus-visible:ring-dark md:h-10 md:pl-11"
      name="searchInput"
      type="search"
      :placeholder="$t(translationKeys.searchPlaceholder)"
      @keyup.enter="navigateToResultPage(), searchQuery = ''"
    />
    <XIcon
      v-show="searchQuery.length"
      class="absolute right-3 top-1 flex size-6 cursor-pointer items-center pl-1 text-grey-semidark opacity-50 transition-opacity duration-150 hover:opacity-100 md:top-2"
      @click="searchQuery = ''; inputElement?.focus()"
    />
    <SearchIcon
      class="absolute inset-y-0 left-0 top-0.5 flex size-7 cursor-pointer items-center pl-1 text-dark md:top-1.5 md:h-7 md:w-8 md:pl-2"
      @click="navigateToResultPage()"
    />
  </OnClickOutside>

  <!-- <SearchAutocomplete :results="autocompleteResults" /> -->
</template>
